<template>
  <div class="medical-prescription-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Deseja realmente cancelar esta consulta?</p>
        <button type="button" class="delete" @click="$emit('close', false)" />
      </header>
      <section class="modal-card-body">
        <p>
          Se optar por enviar ao paciente um Voucher com 100% de desconto para
          remarcação, ele terá a opção de recusar o cupom e, neste caso, será
          realizado o estorno do valor se este já foi pago através da plataforma
          B.D
        </p>

        <div class="mt-4">
          <b-checkbox v-model="generateDiscount" type="is-secondary"
            >Enviar cupom de 100% de desconto.</b-checkbox
          >
        </div>
      </section>
      <footer class="modal-card-foot is-right">
        <b-button
          @click="$emit('close', { generateDiscount })"
          type="is-primary"
          >Cancelar consulta</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    generateDiscount: false,
  }),
};
</script>

<style lang="scss" scoped></style>
