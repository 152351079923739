<template>
  <div class="schedule-view">
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
    <app-header
      goes-back
      icon="clipboard"
      :title="schedule.canceled_at ? 'Consulta cancelada' : 'Consulta'"
      :danger="!!schedule.canceled_at"
    ></app-header>

    <section v-if="!isLoading">
      <div class="card">
        <div class="card-content">
          <div class="patient">
            <div class="patient__image">
              <img :src="patientPhoto" />
            </div>

            <div v-if="schedule.patient" class="patient__info">
              <h2>{{ schedule.patient.name }}</h2>

              <p v-if="schedule.patient.city">
                {{ schedule.patient.city.name }},
                {{ schedule.patient.city.state.name }}
              </p>

              <div class="actions">
                <b-button
                  type="is-info"
                  size="is-small"
                  icon-left="video"
                  @click="openLink('meet', schedule.google_meet)"
                  :disabled="!schedule.google_meet || schedule.canceled_at"
                >
                  Consulta
                </b-button>

                <b-button
                  type="is-dark"
                  icon-left="hdd"
                  size="is-small"
                  :disabled="schedule.canceled_at"
                  @click="onScheduleMeetRecordsClick"
                >
                  Gravações da consulta
                </b-button>

                <b-button
                  @click="onCancelClick()"
                  :disabled="!canCancelSchedule"
                  type="is-danger"
                  icon-left="ban"
                  size="is-small"
                >
                  Cancelar Consulta
                </b-button>
              </div>
            </div>
          </div>
          <schedule-status :schedule="schedule" />
        </div>
      </div>

      <div class="row mt-4" v-if="!schedule.canceled_at">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-header-icon">
                <b-icon icon="clipboard"></b-icon>
              </div>
              <div class="card-header-title">Prontuários</div>
              <div class="card-header-buttons">
                <b-tooltip label="Novo Prontuário">
                  <b-button
                    @click="openRecord()"
                    type="is-dark"
                    icon-left="plus"
                    size="is-small"
                  >
                    Adicionar
                  </b-button>
                </b-tooltip>
              </div>
            </div>
            <div class="card-content custom-scroll">
              <medical-records-table
                v-if="!isLoading && !schedule.canceled_at"
                ref="recordsTable"
                @open="openRecord($event)"
                @delete="onDeleteClick($event)"
                :scheduleId="schedule.id"
              ></medical-records-table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4" v-if="!schedule.canceled_at">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-header-icon">
                <b-icon icon="clipboard"></b-icon>
              </div>
              <div class="card-header-title">Receituários</div>
              <div class="card-header-buttons">
                <b-tooltip
                  size="is-small"
                  position="is-left"
                  label="Novo Receituário"
                  style="margin-right: 5px"
                >
                  <b-button
                    @click="openPrescription()"
                    type="is-dark"
                    icon-left="plus"
                    size="is-small"
                  >
                    Adicionar
                  </b-button>
                </b-tooltip>
                <b-tooltip
                  size="is-small"
                  position="is-left"
                  label="Novo Receituário via Memed"
                  v-if="memedToken"
                >
                  <prescription-memed
                    :patient="schedule.patient"
                    :scheduleId="schedule.id"
                    @reload="reloadPrescriptionsTable()"
                    @view="canViewPrescription($event)"
                  ></prescription-memed>
                </b-tooltip>
              </div>
            </div>
            <div class="card-content custom-scroll">
              <prescriptions-table
                v-if="!isLoading && !schedule.canceled_at"
                ref="prescriptionsTable"
                @open="openPrescription($event)"
                :viewOption="!viewPrescription && !!memedToken"
                :scheduleId="schedule.id"
              ></prescriptions-table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Prescription from '@/modals/Prescription.vue';
import MeetRecordsTable from '@/modals/MeetRecordsTable.vue';
import MedicalRecord from '@/modals/MedicalRecord.vue';
import SchedulesService from '@/services/schedules.service.js';
import PrescriptionsTable from '@/components/PrescriptionsTable.vue';
import MedicalRecordsTable from '@/components/MedicalRecordsTable.vue';
import ScheduleCancelConfirm from '@/modals/ScheduleCancelConfirm.vue';
import MedicalRecordModelService from '@/services/medical-records.service';

export default {
  components: {
    MedicalRecordsTable,
    PrescriptionsTable,
  },
  data: () => ({
    isLoading: true,
    schedule: {},
    viewPrescription: false,
  }),
  computed: {
    patientPhoto() {
      return this.schedule.patient.photo ?? require('@/assets/img/user.png');
    },
    canCancelSchedule() {
      return !this.schedule.performed && !this.schedule.canceled_at;
    },
    ...mapGetters(['memedToken']),
  },
  methods: {
    onCancelClick() {
      this.$buefy.modal.open({
        parent: this,
        component: ScheduleCancelConfirm,
        hasModalCard: true,
        trapFocus: true,
        events: {
          close: (data) => data && this.cancelSchedule(data.generateDiscount),
        },
      });
    },
    cancelSchedule(generateDiscount = false) {
      this.isLoading = true;

      SchedulesService.cancelSchedule(this.schedule.id, {
        generate_discount: generateDiscount,
      })
        .then(({ data }) => {
          this.$buefy.snackbar.open(data.message);
          this.$router.go(-1);
        })
        .catch(({ response }) => {
          let errorMessage = 'Erro inesperado ao cancelar consulta!';

          if (response.status == 403) {
            const { message } = response.data;
            errorMessage = message.content;
          }

          this.$buefy.snackbar.open({
            type: 'is-danger',
            duration: 6500,
            message: errorMessage,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadSchedule(id) {
      this.isLoading = true;

      SchedulesService.getId(id, {
        include: ['patient.user', 'patient.city.state'],
      })
        .then(({ data }) => (this.schedule = data))
        .finally(() => {
          this.isLoading = false;
        });
    },
    openRecord(record = null) {
      this.$buefy.modal.open({
        parent: this,
        component: MedicalRecord,
        hasModalCard: true,
        width: 3000,
        trapFocus: true,
        props: {
          record,
          scheduleId: this.schedule.id,
          patientId: this.schedule.patient_id,
        },
        events: {
          close: (data) => data && this.$refs.recordsTable.loadPatientRecords(),
        },
      });
    },
    onDeleteClick(recordId) {
      this.$buefy.dialog.confirm({
        message: 'Deseja realmente deletar esse prontuário?',
        onConfirm: () => this.deleteRecord(recordId),
      });
    },
    deleteRecord(id) {
      MedicalRecordModelService.deleteRecord(id).then(() => {
        this.$buefy.snackbar.open('Prontuário deletado com sucesso.');
        this.$refs.recordsTable.loadPatientRecords();
      });
    },

    openPrescription(prescription = null) {
      if (prescription && prescription.by_memed) {
        window.MdHub.command.send(
          'plataforma.prescricao',
          'viewPrescription',
          prescription.memed_prescription_id
        );
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: Prescription,
          width: 3000,
          hasModalCard: true,
          trapFocus: true,
          props: {
            prescription,
            schedule: this.schedule,
          },
          events: {
            close: (data) =>
              data && this.$refs.prescriptionsTable.loadPrescriptions(),
          },
        });
      }
    },
    openLink(flag, value) {
      switch (flag) {
        case 'meet':
          window.open(`https://meet.google.com/${value}`, '_blank');
          break;
        case 'drive':
          window.open(value, '_blank');
          break;
      }
    },
    onScheduleMeetRecordsClick() {
      this.$buefy.modal.open({
        component: MeetRecordsTable,
        hasModalCard: true,
        trapFocus: true,
        parent: this,
        width: 600,
        props: {
          scheduleId: this.schedule.id,
        },
      });
    },
    reloadPrescriptionsTable() {
      this.$refs.prescriptionsTable.loadPrescriptions();
    },
    canViewPrescription(value) {
      this.viewPrescription = value;
    },
  },
  mounted() {
    const id = this.$route.params.id;
    this.loadSchedule(id);
  },
};
</script>

<style lang="scss" scoped>
.card {
  .card-content {
    max-height: 50vh;
    overflow: auto;
  }

  .card-content.infos {
    display: flex;

    button {
      margin-right: 10px;
    }
  }
}
.patient {
  display: flex;

  &__image {
    margin-right: 24px;
    border: 1px solid #f5f5f5;
    border-radius: 50%;
    overflow: hidden;
    height: 120px;
    width: 120px;

    img {
      object-fit: cover;
    }
  }

  &__info {
    h2 {
      font-size: 1.4rem;
    }

    .actions {
      margin-top: 8px;

      .button:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
</style>
